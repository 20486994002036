var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-wrap justify-center"},[_c('v-col',{staticClass:"pa-15",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"justify-center"},[_c('div',{staticClass:"column align-self-center"},[_c('v-row',{staticClass:"mb-10"},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"100","src":require("../../assets/logoMis.png")}})],1),_c('v-row',{staticClass:"column"},[_c('div',{staticClass:"mt-3"},[_c('div',{attrs:{"classp":"text-h5 font-weight-regular"}},[_vm._v("Bem vindo!")]),_c('div',{staticClass:"mt-3 caption font-weight-regular"},[_vm._v(" Qual é o seu perfil? ")]),_vm._v(" "),_c('div',{staticClass:"mt-6"},[_c('v-btn',{staticClass:"pa-6 white--text",attrs:{"depressed":"","width":"295","color":"#1C3B7B"},on:{"click":function($event){return _vm.$router.push({
                    name: 'ManagerLogin',
                  })}}},[_vm._v(" Gestão ")])],1),_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"pa-6 white--text",attrs:{"depressed":"","width":"295","color":"#1C3B7B"},on:{"click":function($event){return _vm.$router.push({
                    path: '/login/admin',
                  })}}},[_vm._v(" Administração ")])],1),_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"pa-6 white--text",attrs:{"depressed":"","width":"295","color":"#1C3B7B"},on:{"click":function($event){return _vm.$router.push({
                    name: 'AttendanceLogin',
                  })}}},[_vm._v(" Profissional de atendimento ")])],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-decoration-underline mt-1",attrs:{"text":"","color":"#1C3B7B"},on:{"click":function($event){return _vm.$router.push({
                    name: 'PasswordForm',
                  })}}},[_vm._v(" Esqueci minha senha ")])],1)])])],1)])],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"wallpaper",attrs:{"lazy-src":require('../../assets/login.png'),"src":require('../../assets/login.png')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }