
<template>
  <v-row class="flex-wrap justify-center">
    <v-col cols="6" class="pa-15">
      <v-row class="justify-center">
        <div class="column align-self-center">
          <v-row class="mb-10">
            <v-img
              class="mx-auto"
              width="100"
              src="../../assets/logoMis.png" />
          </v-row>
          <!-- <v-row class="align-center mb-10">
            <div class="text-h4 font-weight-black">MIS</div>
            <div class="ml-2 subtitle-2 font-weight-thin text-start">
              Monitoramento <br />Inteligente por Sensor
            </div>
          </v-row> -->
          <v-row class="column">
            <!-- Default -->
            <div class="mt-3">
              <div classp="text-h5 font-weight-regular">Bem vindo!</div>
              <div class="mt-3 caption font-weight-regular">
                Qual é o seu perfil?
              </div> <div class="mt-6">
                <v-btn
                  depressed
                  width="295"
                  color="#1C3B7B"
                  class="pa-6 white--text"
                  @click="
                    $router.push({
                      name: 'ManagerLogin',
                    })
                  "
                >
                  Gestão
                </v-btn>
              </div>
              <div class="mt-3">
                <v-btn
                  depressed
                  width="295"
                  color="#1C3B7B"
                  class="pa-6 white--text"
                  @click="
                    $router.push({
                      path: '/login/admin',
                    })
                  "
                >
                  Administração
                </v-btn>
              </div>
              <div class="mt-3">
                <v-btn
                  depressed
                  width="295"
                  color="#1C3B7B"
                  class="pa-6 white--text"
                  @click="
                    $router.push({
                      name: 'AttendanceLogin',
                    })
                  "
                >
                  Profissional de atendimento
                </v-btn>
              </div>
              <div class="text-center">
                <v-btn
                  class="text-decoration-underline mt-1"
                  text
                  color="#1C3B7B"
                  @click="
                    $router.push({
                      name: 'PasswordForm',
                    })
                  "
                >
                  Esqueci minha senha
                </v-btn>
              </div>
            </div>
          </v-row>
        </div>
      </v-row>
    </v-col>
    <v-col cols="6" class="pa-0" v-if="!$vuetify.breakpoint.mobile">
      <v-img
        class="wallpaper"
        :lazy-src="require('../../assets/login.png')"
        :src="require('../../assets/login.png')"
      ></v-img>
    </v-col>
  </v-row> 
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({}),
  mounted() {
  },
  methods: {
    ...mapActions("authentication", ["logout"]),
  },
  computed: {
    ...mapGetters({
      hasPermission: "authentication/hasPermission",
    }),
  },
};
</script>

<style>
/* .logo {
  color: #1c3b7b;
} */
.wallpaper {
  background-image: url("../../assets/login.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  flex: 1;
}
</style>
